<template>
  <div>
    <p>
      Each time the LEGO Universe News Network unveils new graphics from the game, you fans respond with a resounding "We want more!" Today, you get your wish and then some! More key characters are now parading around LEGOuniverse.com, and check out these new screenshots of the in-game action!
    </p>

    <!-- pic1AA3738A0C7F4C26F7DCE824CD984158.jpg -->
    <img alt="screenshot 1" src="@/assets/news-network/more-graphics-from-the-game-1.jpg" class="h-265">
    <p><i>Create yourself as a LEGO Minifig!</i></p>

    <!-- picCF5F5AB5EBC1E4D08B445445A5459E7A.jpg -->
    <img alt="screenshot 2" src="@/assets/news-network/more-graphics-from-the-game-2.jpg" class="h-265">
    <p><i>Customize your looks and collect tons of accessories through playing the game.</i></p>

    <!-- pic9B8C4F22309591091194A98C52DA3C07.jpg -->
    <img alt="screenshot 3" src="@/assets/news-network/more-graphics-from-the-game-3.jpg" class="h-265">
    <p><i>You can login with your existing LEGO ID (don't have one? Get one today at <a href="http://www.lego.com/">www.LEGO.com</a>!).</i></p>

    <!-- pic2B03639E91A8A7E1EA8A7D5F5DEAC096.jpg -->
    <img alt="screenshot 4" src="@/assets/news-network/more-graphics-from-the-game-4.jpg" class="h-265">
    <p><i>An epic monument is under construction.</i></p>

    <!-- pic1EBDCB0DC7D3D1C95B1FF78D1749D5A9.jpg -->
    <img alt="screenshot 5" src="@/assets/news-network/more-graphics-from-the-game-5.jpg" class="h-265">
    <p><i>Time to escape from some trouble caused at the monument, build a rocket of your own custom design to launch to other LEGO worlds!</i></p>

    <!-- picDECF621ED119385CABFFD1CF405335A9.jpg -->
    <img alt="screenshot 6" src="@/assets/news-network/more-graphics-from-the-game-6.jpg" class="h-265">
    <p><i>Somewhere deep in a gnarled forest...</i></p>

    <!-- picC8153D7AC023A0C58124C7770B44EB51.jpg -->
    <img alt="screenshot 7" src="@/assets/news-network/more-graphics-from-the-game-7.jpg" class="h-265">
    <p><i>Looks like some pirates have run their ship aground.</i></p>

    <!-- picE10B2A9047DEBEE7F86B85D8D752A6C0.jpg -->
    <img alt="screenshot 8" src="@/assets/news-network/more-graphics-from-the-game-8.jpg" class="h-265">
    <p><i>Maybe these ninjas will help? Then again, maybe not!</i></p>

    <!-- pic4DF25A904CDB25A1FB4A3035A60153EF.jpg -->
    <img alt="screenshot 9" src="@/assets/news-network/more-graphics-from-the-game-9.jpg" class="h-265">
    <p><i>After a hard day adventuring with ninjas, pirates and who knows what else, it's always good to take a break and rock out with some friends.</i></p>

    <p>
      Stay tuned to the LEGO Universe News Network to get more graphics from the game! We're your official source for the latest on the first massively mutiplayer online game for LEGO fans!
    </p>
  </div>
</template>
